





















































import { Component, Mixins } from 'vue-property-decorator';
import GroupComponent from '@common-src/mixins/group-component';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { ThirdPartyListEntityModel, ThirdPartyListFilterModel } from '@common-src/entity-model/third-party-list-entity';
import ThirdPartlistService from '@common-src/service/thirdpart-list';

@Component({
    components: {
    }
})
export default class ThirdPartyListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    ThirdPartyListEntityModel = ThirdPartyListEntityModel;

    created() {
        this.initTable({
            service: ThirdPartlistService,
            queryModel: new ThirdPartyListFilterModel(),
            tableColumns: ThirdPartyListEntityModel.getTableColumns()
        });
        this.getList();
    }

    detailClick(model: ThirdPartyListEntityModel) {
        // (this.$refs.thingsFeatureDetailDrawer as ThingsFeatureDetailDrawer).drawerShow(model);
    }
}

